<template>
  <v-container>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Hosting</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row class="mt-4">
          <v-col cols="12" md="12">
            <v-card class="mx-auto">
              <v-card-subtitle>
                <v-row>
                  <v-col cols="12" md="1">
                    <v-btn class="ma-2" outlined x-large fab color="primary">
                      <v-icon color="primary">{{ "mdi-file-cloud" }}</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="11">
                    <p class="mt-8">
                      Planes de hosting asequibles para pequeñas y medianas
                      empresas que deseen incursionar en la web.
                    </p>
                  </v-col>
                </v-row>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <div class="mt-15 mb-5">
          <p class="mt-5 text-h2 font-weight-bold text-center">Emprendedor</p>
        </div>
        <v-row>
          <v-col cols="12" md="2"></v-col>
          <v-col
            cols="12"
            v-for="(plan, i) in plans"
            :key="i"
            md="4"
            class="pt-10"
          >
            <div v-if="i == 0 && plan.price > 0" class="mt-10"></div>
            <div v-if="i == 1 && plan.price > 0" class="mt-10"></div>
            <v-card
              v-if="plan.price > 0 && (i == 0 || i == 1)"
              class="mx-auto"
              elevation="10"
              max-width="95%"
            >
              <v-card-title
                v-if="i == 0 && plan.price > 0"
                class="justify-center"
                style="background-color: #0e73b1"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>
              <v-card-title
                v-if="i == 1 && plan.price > 0"
                class="justify-center"
                style="background-color: #e9501c"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>

              <v-divider></v-divider>
              <v-card-text>
                <br />
                <p
                  v-if="i == 0 && plan.price > 0"
                  class="text-center text-h2 font-weight-black"
                  style="color: #0e73b1"
                >
                  {{ plan.price }} CLP
                </p>
                <p
                  v-if="i == 1 && plan.price > 0"
                  class="text-center text-h2 font-weight-black"
                  style="color: #e9501c"
                >
                  {{ plan.price }} CLP
                </p>

                <p class="text-center" v-if="plan.price > 0 && i == 0">
                  <strong> IVA incluido</strong>
                </p>
                <p class="text-center" v-if="plan.price > 0 && i == 1">
                  <strong> IVA incluido</strong>
                </p>
              </v-card-text>
              <v-divider v-if="plan.price > 0 && i == 0"></v-divider>
              <v-divider v-if="plan.price > 0 && i == 1"></v-divider>
              <v-card-subtitle v-if="plan.price > 0 && i == 0">{{
                plan.description
              }}</v-card-subtitle>
              <v-card-subtitle v-if="plan.price > 0 && i == 1">{{
                plan.description
              }}</v-card-subtitle>
              <v-divider v-if="plan.price > 0 && i == 0"></v-divider>
              <v-divider v-if="plan.price > 0 && i == 1"></v-divider>
              <v-card-actions class="justify-center pt-3 pb-3">
                <v-btn
                  @click="contratar(plan.id)"
                  v-if="i == 0 && plan.price > 0"
                  class="white--text"
                  color="#0e73b1"
                >
                  <v-icon right dark> mdi-cart </v-icon> Adquirir
                </v-btn>
                <v-btn
                  @click="contratar(plan.id)"
                  v-if="i == 1 && plan.price > 0"
                  class="white--text"
                  color="#e9501c"
                >
                  <v-icon right dark> mdi-cart </v-icon> Adquirir
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="2"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="mt-15 mb-15">
              <p class="text-h2 font-weight-bold text-center">
                Business <br />
              </p>
            </div>
          </v-col>
          <v-row class="mb-10">
            <v-col cols="12" md="2"></v-col>
            <v-col
              cols="12"
              v-for="(plan, i) in plans"
              :key="i > 1"
              md="4"
              class="pt-10"
            >
              <v-card
                v-if="plan.price > 0 && (i == 2 || i == 3)"
                class="mx-auto"
                elevation="10"
                max-width="90%"
              >
                <v-card-title
                  v-if="i == 2 && plan.price > 0"
                  class="justify-center"
                  style="background-color: #0e73b1"
                >
                  <div class="justify-center white--text">{{ plan.name }}</div>
                </v-card-title>
                <v-card-title
                  v-if="i == 3 && plan.price > 0"
                  class="justify-center"
                  style="background-color: #e9501c"
                >
                  <div class="justify-center white--text">{{ plan.name }}</div>
                </v-card-title>

                <v-divider></v-divider>
                <v-card-text>
                  <br />
                  <p
                    v-if="i == 2 && plan.price > 0"
                    class="text-center text-h2 font-weight-black"
                    style="color: #0e73b1"
                  >
                    {{ plan.price }} CLP
                  </p>
                  <p
                    v-if="i == 3 && plan.price > 0"
                    class="text-center text-h2 font-weight-black"
                    style="color: #e9501c"
                  >
                    {{ plan.price }} CLP
                  </p>

                  <p class="text-center" v-if="plan.price > 0 && i == 2">
                    <strong> IVA incluido</strong>
                  </p>
                  <p class="text-center" v-if="plan.price > 0 && i == 3">
                    <strong> IVA incluido</strong>
                  </p>
                </v-card-text>
                <v-divider v-if="plan.price > 0 && i == 2"></v-divider>
                <v-divider v-if="plan.price > 0 && i == 3"></v-divider>
                <v-card-subtitle v-if="plan.price > 0 && i == 2">{{
                  plan.description
                }}</v-card-subtitle>
                <v-card-subtitle v-if="plan.price > 0 && i == 3">{{
                  plan.description
                }}</v-card-subtitle>
                <v-divider v-if="plan.price > 0 && i == 2"></v-divider>
                <v-divider v-if="plan.price > 0 && i == 3"></v-divider>
                <v-card-actions class="justify-center pt-3 pb-3">
                  <v-btn
                    @click="contratar(plan.id)"
                    v-if="i == 2 && plan.price > 0"
                    class="white--text"
                    color="#0e73b1"
                  >
                    <v-icon right dark> mdi-cart </v-icon> Adquirir
                  </v-btn>
                  <v-btn
                    @click="contratar(plan.id)"
                    v-if="i == 3 && plan.price > 0"
                    class="white--text"
                    color="#e9501c"
                  >
                    <v-icon right dark> mdi-cart </v-icon> Adquirir
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="2"></v-col>
          </v-row>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import Config from "../../config";
export default {
  data: () => ({
    plans: [],
  }),
  computed: {},
  mounted() {
    this.getPlans();
  },
  watch: {},
  methods: {
    async getPlans() {
      let request = {
        id_service: Config.idHosting,
      };
      axios
        .post("/plan-by-service", request)
        .then((response) => {
          console.log(response.data);
          this.plans = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "");
        });
    },
    contratar(id_plan) {
      this.$router.push({
        name: "create-contract-by-customer",
        params: {
          id_plan: id_plan,
          id_servicio: Config.idBoletaElectronica,
        },
      });
    },
  },
};
</script>